import React from "react";
import { Link, useLocation } from "react-router-dom";
import {  PhoneIcon } from "@heroicons/react/solid";
export default function Navbar({ fixed }) {
  const location = useLocation();

  const navigation = [
    { link: '/', text: 'home' },
    { link: '/about', text: 'About' },
    { link: '/Project', text: 'Project' },
    { link: '/Contact', text: 'Contact' },
    { link: '/Specification', text: 'Specification' },
    { link: '/Gallery', text: 'Gallery' },
  ];
  
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  return (
    <>
    
      <nav className="relative z-50 bg-slate-100 pb-2 font-navfont  p-2 sticky top-0">
        <div className=" px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <div className="mx-auto ">
             
              <Link to="/">
                <img
                  src="/images/GK_logo1.webp"
                  alt="Logo"
              className="px-3"
                />
              </Link>
             
            </div>
            
            <button
              className="text-black cursor-pointer text-xl leading-none -mx-3 lg:px-3 py-1 text-crimson-600  rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-7 w-8 "
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center " +
              (navbarOpen ? " flex" : " hidden")
            }
            id="example-navbar-danger"
          >
            <ul className="flex flex-col text-lg lg:text-xl font-bold  lg:flex-row list-none lg:ml-auto">
            {navigation.map((nav, key) => { 
  if (nav.text === "Project") {
    const isActiveProject = location.pathname === "/CurrentProjects" || location.pathname === "/CompletedProjects";

    return (
      <>
        <div className="relative border-t-2 lg:border-hidden inline-block text-left lg:hidden">
          <Link to="/CurrentProjects" onClick={() => setNavbarOpen(!navbarOpen)}
            className={`px-3 py-2 flex items-center capitalize font-bold leading-snug text-gray-800 hover:opacity-75 ${location.pathname === "/CurrentProjects" ? 'text-crimson-600' : ''}`}
          >
            Current Projects
          </Link>
          <Link to="/CompletedProjects" onClick={() => setNavbarOpen(!navbarOpen)}
            className={`px-3 py-2 border-t-2 lg:border-hidden flex items-center capitalize font-bold leading-snug text-gray-800 hover:opacity-75 ${location.pathname === "/CompletedProjects" ? 'text-crimson-600' : ''}`}
          >
            Completed Projects
          </Link>
        </div>
        <div className="relative inline-block text-left hidden lg:block">
          <button type="button" onClick={() => setDropdownOpen(!dropdownOpen)} className={`py-2 px-3 items-center capitalize font-bold leading-snug text-gray-800 hover:opacity-75 ${isActiveProject ? 'text-crimson-600' : ''}`}>
            Project
          </button>
          {dropdownOpen && (
            <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
              <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <Link to="/CurrentProjects" onClick={() => setDropdownOpen(!dropdownOpen)} className="block bg-slate-50 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                  Current Projects
                </Link>
                <Link to="/CompletedProjects" onClick={() => setDropdownOpen(!dropdownOpen)} className="block bg-slate-50 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                  Completed Projects
                </Link>
              </div>
            </div>
          )}
        </div> 
      </>
    )
  } else {
    return (
      <li className="nav-item">
        <Link 
          to={nav.link} 
          onClick={() => setNavbarOpen(!navbarOpen)}
          className={`px-3 py-2 flex border-t-2 lg:border-hidden items-center capitalize font-bold leading-snug text-gray-800 hover:opacity-75 ${location.pathname === nav.link ? 'text-crimson-600' : ''}`}
        >
          {nav.text}
        </Link>
      </li> 
    )
  }
})}          
<li className="nav-item">
                <a
                  className="px-3 py-2 border-t-2 border-b-2 lg:border-hidden flex items-center  capitalize font-bold leading-snug text-gray-800 hover:opacity-75"
                  href="https://www.ysventures.in"
                >
                  Interiors
                </a>
              </li> 
<li className="nav-item">
                <a
                  className="px-3 py-2 border-t-2 border-b-2 lg:border-hidden flex items-center  capitalize font-bold leading-snug text-gray-800 hover:opacity-75"
                  href="/Blog"
                >
                  Blog
                </a>
            
              </li> 
<li className="nav-item">
                <a
                  className="px-3 py-2 border-t-2 border-b-2 lg:border-hidden flex items-center  capitalize font-bold leading-snug text-gray-800 hover:opacity-75"
                  href="/"
                >
                    <p className="flex text-xl items-center justify-start mb-4 mx-2  ">
              <PhoneIcon className="h-8 w-8 text-crimson-600 hover:text-crimson-800"/> 
              <a href="tel:+918610695122 ">  <span className="lg:text-xl text-crimson-600 hover:text-crimson-800 text-left mx-2  ">| +91-8610695122</span> </a>
            </p>
                </a>
            
              </li> 
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}