import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './Components/App';
import './index.css'; // Ensure CSS is correctly imported
import * as serviceWorkerRegistration from './Components/service-worker-registration';
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';

const tagManagerArgs = {
  gtmId: 'GTM-M2RR25N',
};
TagManager.initialize(tagManagerArgs);
ReactGA.initialize('UA-214435749-1');
ReactGA.pageview(window.location.pathname + window.location.search);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <div className="bg-slate-50 font-robotoc text-slate-800">
      <App />
    </div>
  </React.StrictMode>
);

// Register the service worker for PWA capabilities
serviceWorkerRegistration.register();

// For performance measuring
reportWebVitals();
