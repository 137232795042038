import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ChevronDoubleUpIcon } from "@heroicons/react/solid";
import { useWindowScroll } from "react-use";

const LgscrollToTop = () => {
  const { y: pageYOffset } = useWindowScroll();
  const [visible, setVisibility] = useState(false);
  const location = useLocation(); // Detect route changes

  // Show the scroll-to-top button when scrolling down
  useEffect(() => {
    if (pageYOffset > 400) {
      setVisibility(true);
    } else {
      setVisibility(false);
    }
  }, [pageYOffset]);

  // Automatically scroll to the top on route change
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <>
      {visible && (
        <div
          className="fixed bottom-6 right-6 bg-slate-100 rounded-full shadow-lg p-2 cursor-pointer"
          onClick={scrollToTop}
        >
          {/* <ChevronDoubleUpIcon className="h-9 w-9 text-crimson-500 hover:text-crimson-800 animate-bounce" /> */}
        </div>
      )}
    </>
  );
};

export default LgscrollToTop;
