import React from "react";

const SkeletonLoader = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50">
      <div className="w-48 h-6 bg-gray-300 animate-pulse rounded mb-4"></div>
      <div className="w-64 h-4 bg-gray-300 animate-pulse rounded mb-2"></div>
      <div className="w-full max-w-md h-40 bg-gray-300 animate-pulse rounded"></div>
    </div>
  );
};

export default SkeletonLoader;
