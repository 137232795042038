import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Footer } from "../Components/footer";
import Navbar from "../Components/Nav";
import FloatingWhatsApp from 'react-floating-whatsapp';
import LgscrollToTop from './Lgtop';
import SkeletonLoader from './SkeletonLoader';
// Lazy-loaded pages
const Home = lazy(() => import('../Pages/home'));
const About = lazy(() => import('../Pages/about'));
const Contact = lazy(() => import('../Pages/Contact'));
const Gallery = lazy(() => import('../Pages/Gallery'));
const CurrentProject = lazy(() => import('../Pages/CurrentProject'));
const CompletedProjects = lazy(() => import('../Pages/CompletedProjects'));
const Akrthi = lazy(() => import('../Pages/Akrthi'));
const Thiara = lazy(() => import('../Pages/Thiara'));
const SaiShree = lazy(() => import('../Pages/SaiShree'));
const Srisaithiara = lazy(() => import('../Pages/Srisaithiara'));
const Srisairamya = lazy(() => import('../Pages/Srisairamya'));
const Tharun = lazy(() => import('../Pages/Tharun'));
const Srisaitharun = lazy(() => import('../Pages/Srisaitharun'));
const Srisaikubera = lazy(() => import('../Pages/Srisaikubera'));
const Srisaibaba = lazy(() => import('../Pages/Srisaibaba'));
const Srisaishanmuga = lazy(() => import('../Pages/Srisaishanmuga'));
const Srisaiaditiya = lazy(() => import('../Pages/Srisaiaditiya'));
const Thanshikaa = lazy(() => import('../Pages/Thanshikaa'));
const Rakeshpranav = lazy(() => import('../Pages/Rakeshpranav'));
const Specification = lazy(() => import('../Pages/Specification'));
const Santhosh = lazy(() => import('../Pages/Santhosh'));
const Riya = lazy(() => import('../Pages/Riya'));
const Disclaimer = lazy(() => import('../Pages/Disclaimer'));
const Privacy = lazy(() => import('../Pages/Privacy'));
const Blog = lazy(() => import('../Pages/Blog'));
const Bricksblog = lazy(() => import('./Blog/Bricksblog'));
const Flatsblog = lazy(() => import('./Blog/Flatsblog'));
const ValentinesBlog = lazy(() => import('./Blog/ValentinesBlog'));
const Trends2025 = lazy(() => import('./Blog/Trends2025'));
const WhyAdambakkam = lazy(() => import('./Blog/WhyAdambakkam'));
const RiyaEnclaveBlog = lazy(() => import('./Blog/RiyaEnclaveBlog'));
const DigitalMarketing = lazy(() => import('./Blog/DigitalMarketing'));
const Error = lazy(() => import('../Pages/404'));



const Rout = () => {
  return (
    <div>
      <Router>
        <Navbar />
        <LgscrollToTop />
        
        <Suspense fallback={<SkeletonLoader />}>
          <Routes>
            <Route exact path='/' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/Contact' element={<Contact />} />
            <Route path='/Gallery' element={<Gallery />} />
            <Route path='/CurrentProjects' element={<CurrentProject />} />
            <Route path='/CompletedProjects' element={<CompletedProjects />} />
            <Route path='/Akrthi' element={<Akrthi />} />
            <Route path='/Thiara' element={<Thiara />} />
            <Route path='/SaiShree' element={<SaiShree />} />
            <Route path='/Specification' element={<Specification />} />
            <Route path='/Srisaithiara' element={<Srisaithiara />} />
            <Route path='/Srisairamya' element={<Srisairamya />} />
            <Route path='/Srisaitharun' element={<Srisaitharun />} />
            <Route path='/Srisaikubera' element={<Srisaikubera />} />
            <Route path='/Tharun' element={<Tharun />} />
            <Route path='/Riya' element={<Riya />} />
            <Route path='/Santhosh' element={<Santhosh />} />
            <Route path='/Srisaibaba' element={<Srisaibaba />} />
            <Route path='/Srisaishanmuga' element={<Srisaishanmuga />} />
            <Route path='/Srisaiaditiya' element={<Srisaiaditiya />} />
            <Route path='/Thanshikaa' element={<Thanshikaa />} />
            <Route path='/Rakeshpranav' element={<Rakeshpranav />} />
            <Route path='/Disclaimer' element={<Disclaimer />} />
            <Route path='/Privacy' element={<Privacy />} />
            <Route path='/Blog' element={<Blog />} />
            <Route path='/Bricksblog' element={<Bricksblog />} />
            <Route path='/Flatsblog' element={<Flatsblog />} />
            <Route path='/Trends2025' element={<Trends2025 />} />
            <Route path='/ValentinesBlog' element={<ValentinesBlog />} />
            <Route path='/WhyAdambakkam' element={<WhyAdambakkam />} />
            <Route path='/DigitalMarketing' element={<DigitalMarketing />} />
            <Route path='/RiyaEnclaveBlog' element={<RiyaEnclaveBlog />} />
            <Route path='*' element={<Error />} />
          </Routes>
        </Suspense>

        <Footer />

        <FloatingWhatsApp
          phoneNumber="+918610695122"
          accountName="GK Promoters"
          avatar="./images/whatsapp.webp"
          position="top"
          className='hidden lg:flex'
        />
      </Router>
    </div>
  );
};

export default Rout;
